
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App h1 {
  padding-left: 10px;
}

.breadcrumbs {
  padding: 8px 10px 8px 10px;
}

.breadcrumb {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.folders {
  width: 100%;
  text-align: left;
  list-style: none;
  padding-left: 0px;
  font-size: 14pt;
  font-weight: bold;
  color: #164a81;
}

.folders li:before {
  content: "> ";
}

.folders li {
  padding-left: 10px;
  padding: 4px 10px 4px 10px;
  cursor: pointer;
}

.folders li:nth-child(odd) {
  background-color: #87bdd8;
}

.folders li:nth-child(even) {
  background-color: #b7d7e8;
}

.folders li:hover {
  background-color: #6c7b83;
  color: white;
}

.chop-image {
  display: inline-block;
  text-align: left;
  padding-left: 10px;
}

.chop-image img {
  max-height: 220px;
}

.sign-out-button {
  float: right;
  margin-right: 10px;
}

.sign-out-button span {
  margin-right: 8px;
}
